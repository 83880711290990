import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Category } from '../@types/category';
import { PageType } from '../@types/pageType';
import { Recipe } from '../@types/recipe';
import type { RootState } from './store';

// Define a type for the slice state
interface PageState {
  pageType?: PageType;
  category?: Category;
  recipe?: Recipe;
}

// Define the initial state using that type
const initialState: PageState = {
  pageType: PageType.Home,
};

export const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    setPageType: (state, action: PayloadAction<PageType>) => {
      state.pageType = action.payload;
    },
    setCategory: (state, action: PayloadAction<Category>) => {
      state.category = action.payload;
    },
    setRecipe: (state, action: PayloadAction<Recipe>) => {
      state.recipe = action.payload;
    },
  },
});

export const { setPageType, setCategory, setRecipe } = pageSlice.actions;

export default pageSlice.reducer;
