import React from 'react';
import { Provider } from 'react-redux';

import { store } from '../state/store';

type Props = {
  element: React.ReactElement;
};

export default ({ element }: Props) => {
  return <Provider store={store}>{element}</Provider>;
};
