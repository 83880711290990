import { configureStore } from '@reduxjs/toolkit';
import navSlice from './navSlice';
import pageSlice from './pageSlice';
import searchSlice from './searchSlice';

export const store = configureStore({
  reducer: {
    search: searchSlice,
    page: pageSlice,
    nav: navSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
