module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"My Recipes","short_name":"My Recipes","display":"standalone","lang":"en","start_url":"/","background_color":"#FF6A5E","theme_color":"#FF6A5E","icon":"src/images/icon.png","icon_options":{"purpose":"any maskable"},"legacy":false,"shortcuts":[{"name":"Meat Recipes","short_name":"Meat","description":"List of all meat recipes","url":"/meat/"},{"name":"Vegetarian Recipes","short_name":"Vegetarian","description":"List of all vegetarian recipes","url":"/vegetarian/"},{"name":"Fish Recipes","short_name":"Fish","description":"List of all fish recipes","url":"/fish/"},{"name":"Latest Recipes","short_name":"Latest","description":"List of the latest recipes","url":"/latest-recipes/"}],"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a42fd6463ee006354907016be1402a63"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/*"],"appendScript":"/home/runner/work/my-recipes/my-recipes/src/scripts/serviceWorkerCustom.js","workboxConfig":{"cacheId":"my-recipes-offline","globPatterns":["icons/*","img/**/*","page-data/**/*","**/*.html","static/**/*.svg"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
