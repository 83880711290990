exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-auth-login-tsx": () => import("./../../../src/pages/auth/login.tsx" /* webpackChunkName: "component---src-pages-auth-login-tsx" */),
  "component---src-pages-category-url-tsx": () => import("./../../../src/pages/{Category.url}.tsx" /* webpackChunkName: "component---src-pages-category-url-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-latest-recipes-tsx": () => import("./../../../src/pages/latest-recipes.tsx" /* webpackChunkName: "component---src-pages-latest-recipes-tsx" */),
  "component---src-pages-recipe-recipe-url-tsx": () => import("./../../../src/pages/recipe/{Recipe.url}.tsx" /* webpackChunkName: "component---src-pages-recipe-recipe-url-tsx" */),
  "component---src-pages-settings-tsx": () => import("./../../../src/pages/settings.tsx" /* webpackChunkName: "component---src-pages-settings-tsx" */)
}

