import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from './store';

// Define a type for the slice state
interface NavState {
  navOpen: boolean;
}

// Define the initial state using that type
const initialState: NavState = {
  navOpen: false,
};

export const navSlice = createSlice({
  name: 'nav',
  initialState,
  reducers: {
    toggleNavOpen: (state) => {
      state.navOpen = !state.navOpen;
    },
    closeNav: (state) => {
      state.navOpen = false;
    },
  },
});

export const { toggleNavOpen, closeNav } = navSlice.actions;

export default navSlice.reducer;
